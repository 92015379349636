import React from 'react';
import '.././App.css'; // Import your CSS

const SectionBox = ({
                        title = "Default Title",
                        text,
                        backgroundColor = "#A9A9A9",
                        borderRadius = "15px",
                        borderColor = "#ffffff",
                        maxWidth = "80%",
                        padding = "50px",
                        boxShadow = "13px 14px 6px rgba(0, 0, 0, 0.3)",
                        border = '2.5px solid',
                        textColor = 'white',
                        marginBottom = '0px',
                        marginTop = '0px',
                        width = '100%',
                    }) => {
    return (
        <div
            className="section-box"
            style={{
                backgroundColor,
                borderRadius,
                borderColor,
                maxWidth,
                padding,
                boxShadow,
                color: `${textColor}`,
                border: `${border} ${borderColor}`,
                marginBottom,
                marginTop,
                width
            }}
        >
            {title && <h2>{title}</h2>}
            {text && <p>{text}</p>}
        </div>
    );
};

export default SectionBox;
