import React from 'react';
import DivSectionsBox from './DivSectionBox';  // Assuming you already have this component
import SkillBox from './SkillBox/SkillBox';
import SkillBoxContainer from "./SkillBoxContainer/SkillBoxContainer";

const CustomSkillSection = ({
                                title = "Skills",       // Default title
                                backgroundColor = "black",  // Default background color
                                maxWidth = "100%",       // Default max-width for the container
                                marginBottom = "30px",       // Default max-width for the container
                                skills = [],            // Array of skills to display
                            }) => {
    return (
        <div style={{marginBottom:'25px'}}>
            <DivSectionsBox backgroundColor={backgroundColor} maxWidth='100%' style = {{marginBottom:'20px'}}>
                <div style={{display:"flex", direction:"initial", marginBottom:"20px"}}>
                    <h2>{title}</h2>
                </div>
                <SkillBoxContainer style={{maxWidth, width:'100%'}}>
                    {skills.map((skill, index) => (
                        <SkillBox
                            key={index}
                            icon={skill.icon}
                            iconName={skill.iconName}
                            iconColor={skill.iconColor}
                        />
                    ))}
                </SkillBoxContainer>
            </DivSectionsBox>
        </div>
    );
};

export default CustomSkillSection;
