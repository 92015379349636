import './../App.css';
import React from 'react';
import  {Link} from "react-router-dom";

function Navbar(props) {
    return (
        <div className='navbar'>
            <div className='navbar-logo'>
                Jay's Portfolio
            </div>
            <ul className='navbar-menu'>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/resume'>Resume</Link></li>
                <li><Link to='/skills'>Skills</Link></li>
                {/*<li><Link to='/projects'>Projects</Link></li>*/}
            </ul>
        </div>
    );
}

export default Navbar;
