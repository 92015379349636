import './../App.css';
import React from 'react';
import Navbar from "../components/Navbar";
import SectionBox from "../components/SectionBox";
import BackgroundImage from "../components/BackgroundImage/BackgroundImage";
import HoverLinkButton from "../components/HoverLinkButton/HoverLinkButton";
import VBox from "../components/Vbox/VBox";
import HBox from "../components/Hbox/HBox";



function Home(props) {

    return (
        <div className='page' style={{maxHeight:'100vh', overflow:"hidden"}}>
            <Navbar></Navbar>
            <VBox>
                <BackgroundImage
                    imageUrl="https://media.licdn.com/dms/image/v2/D5616AQGEEi0X6k33tg/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1674247401000?e=1730332800&v=beta&t=oi9hxbmB_ZdEYg3csFxUEl-pMfKz8wkyrFTgiExRF_Q"
                    blur={5}                // Apply a 10px blur to the background
                    transition={true}        // Enable transition effect
                    transitionDuration="2.5s"  // Set transition duration to 3 seconds
                >
                    <VBox>

                        <HBox maxWidth='90%'>
                            <img
                                src={'https://media.licdn.com/dms/image/v2/D5603AQGPHmxbDCHZOg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1689805218232?e=1730332800&v=beta&t=CkpIS5GE1ssl0uzDSMDTOZhAn_PNiBGeTHIw1_4GnxE'}
                                alt="Jay Image"
                                style={{
                                    borderRadius: '50%',
                                    maxWidth: '50%',
                                    marginTop: '20px',
                                    boxShadow: '13px 14px 6px rgba(0, 0, 0, 0.3)'
                                }}
                                // className='image-container'
                            />
                            <SectionBox
                                title="Hi I'm Jay!"
                                text={"I am a third year Computer Science and Business Administration student, currently on Co-Op at Glaukos as a Software Engineer."}
                                padding='30px'
                                maxWidth='50%'
                                // width='110%'
                            />
                        </HBox>
                        <HBox>
                            <div style={{
                                fontSize: 'calc(50px + 2vmin)',
                                marginBottom: '20px',
                                marginTop: '20px',
                                textShadow: '13px 14px 6px rgba(0, 0, 0, 0.3)'
                            }}>Links
                            </div>
                        </HBox>
                        <div style={{maxWidth:'100%', marginBottom:'20px'}}>
                        <HBox maxWidth='100%'>
                            <HoverLinkButton
                                icon="https://raw.githubusercontent.com/gilbarbara/logos/29e8719bf78915c7a82a26a6c203f53c4cb8fff2/logos/linkedin-icon.svg"
                                text="LinkedIn"
                                redirectLink="https://www.linkedin.com/in/jay-atal/"
                            />

                            <HoverLinkButton
                                icon="https://raw.githubusercontent.com/gilbarbara/logos/29e8719bf78915c7a82a26a6c203f53c4cb8fff2/logos/github-icon.svg"
                                text="GitHub"
                                redirectLink="https://github.com/Jay-Atal"
                            />
                            <HoverLinkButton
                                icon="https://raw.githubusercontent.com/gilbarbara/logos/29e8719bf78915c7a82a26a6c203f53c4cb8fff2/logos/youtube-icon.svg"
                                text="YouTube"
                                redirectLink="https://www.youtube.com/@JayFlyerVids"
                            />
                        </HBox>
                        </div>


                    </VBox>
                </BackgroundImage>
            </VBox>
        </div>

    );
}

export default Home;
