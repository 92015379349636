// import React from 'react';
// import './HBox.css'; // Import your CSS file
//
// const HBox = ({ children, maxHeight = '100%', maxWidth = '100%' }) => {
//     return (
//         <div
//             className="hbox-container"
//             style={{
//                 maxHeight: maxHeight,
//                 maxWidth: maxWidth,
//             }}
//         >
//             {children}
//         </div>
//     );
// };
//
// export default HBox;
// import React from 'react';
// import './HBox.css'; // Import your CSS file
//
// const HBox = ({ children, maxHeight = '100%', maxWidth = '100%' }) => {
//     return (
//         <div
//             className="hbox-container"
//             style={{
//                 maxHeight: maxHeight,
//                 maxWidth: maxWidth,
//             }}
//         >
//             {children}
//         </div>
//     );
// };
//
// export default HBox;
import React from 'react';
import './HBox.css'; // Import your CSS file

const HBox = ({ children, maxHeight = '100%', maxWidth = '100%', marginBottom = '20px' }) => {
    return (
        <div
            className="hbox-container"
            style={{
                maxHeight: maxHeight,
                maxWidth: maxWidth,
                marginBottom: marginBottom,
            }}
        >
            {children}
        </div>
    );
};

export default HBox;
