// import React from 'react';
// import './SkillBoxContainer.css';
//
// const SkillBoxContainer = ({ children }) => {
//     return (
//         <div className="skill-box-container">
//             {children}
//         </div>
//     );
// };
//
// export default SkillBoxContainer;
import React from 'react';
import './SkillBoxContainer.css';

const SkillBoxContainer = ({ children }) => {
    return (
        <div className="skill-box-container">
            {children}
        </div>
    );
};

export default SkillBoxContainer;
