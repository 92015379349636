import React, { useState, useRef, useEffect } from 'react';
import './HoverLinkButton.css';

const HoverLinkButton = ({
                             text,
                             icon,
                             initialBgColor = 'white',
                             initialTextColor = 'black',
                             hoverBgColor = '#000000',
                             hoverTextColor = '#fff',
                             borderRadius = '50px',
                             redirectLink = '#',
                             imgBorderRadius = '100%',
                             imgBorderColor = 'black',
                             imgHoverBorderColor = 'white',
                             imgBgColor = 'white',
                             imgHoverBgColor = 'white',
                         }) => {
    const [hovered, setHovered] = useState(false);
    const buttonRef = useRef(null);
    const [dynamicStyles, setDynamicStyles] = useState({
        width: '200px',
        height: '50px',
        fontSize: '16px',
        iconSize: '24px',
    });

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const newStyles = calculateDynamicStyles(screenWidth);
            setDynamicStyles(newStyles);
        };

        handleResize(); // Initial calculation
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calculateDynamicStyles = (screenWidth) => {
        // Example: Adjust dimensions and font size proportionally to screen width
        const width = `${Math.max(100, screenWidth * 0.2)}px`;
        const height = `${Math.max(30, screenWidth * 0.05)}px`;
        const fontSize = `${Math.max(12, screenWidth * 0.02)}px`;
        const iconSize = `${Math.max(16, screenWidth * 0.03)}px`;

        return {
            width,
            height,
            fontSize,
            iconSize,
        };
    };

    return (
        <a
            href={redirectLink}
            className={`hover-link-button ${hovered ? 'hovered' : ''}`}
            ref={buttonRef}
            style={{
                ...dynamicStyles,
                borderRadius,
                backgroundColor: hovered ? hoverBgColor : initialBgColor,
                color: hovered ? hoverTextColor : initialTextColor,
                lineHeight: dynamicStyles.height,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {typeof icon === 'string' ? (
                <img
                    src={icon}
                    alt="icon"
                    style={{
                        width: dynamicStyles.iconSize,
                        height: dynamicStyles.iconSize,
                        borderRadius: imgBorderRadius,
                        borderColor: hovered ? imgHoverBorderColor : imgBorderColor,
                        backgroundColor: hovered ? imgHoverBgColor : imgBgColor,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        marginRight: text ? '8px' : '0',
                    }}
                />
            ) : (
                icon && (
                    <icon.type
                        style={{
                            fontSize: dynamicStyles.iconSize,
                            borderRadius: imgBorderRadius,
                            borderColor: hovered ? imgHoverBorderColor : imgBorderColor,
                            backgroundColor: hovered ? imgHoverBgColor : imgBgColor,
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            marginRight: text ? '8px' : '0',
                        }}
                    />
                )
            )}
            {text && <span>{text}</span>}
        </a>
    );
};

export default HoverLinkButton;
