import React from 'react';
import './RoleBox.css';

const RoleBox = ({
                     logoUrl = 'https://via.placeholder.com/50',
                     companyName = 'Company Name',
                     roles = {
                         role: 'Job Title',
                         dateLine: 'Jan 2020 - Present',
                         location: 'Location',
                         highlights: [], // New optional highlights property
                         bulletPoints: []
                     },
                     bordered = true,
                     borderWidth = '3px',
                     borderColor = 'black',
                     imageRadius = '50%',
                     maxWidth = '75px',
                     maxHeight = '75px',
                 }) => {
    // Ensure roles is always treated as an array
    const rolesArray = Array.isArray(roles) ? roles : [roles];

    return (
        <div className="role-box">
            <img
                src={logoUrl}
                alt={`${companyName} logo`}
                className="company-logo"
                style={{
                    border: bordered ? `${borderWidth} solid ${borderColor}` : 'none',
                    borderRadius: imageRadius,
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                }}
            />
            <div className="role-box-content">
                <h3 className="company-name">{companyName}</h3>
                {rolesArray.map((roleDetail, index) => (
                    <div key={index} className="role-details">
                        <p className="role">{roleDetail.role}</p>
                        <p className="date-line">{roleDetail.dateLine}</p>
                        <p className="location">{roleDetail.location}</p>

                        {/* Render non-bulleted highlights if provided */}
                        {roleDetail.highlights && roleDetail.highlights.length > 0 && (
                            <ul className="highlights">
                                {roleDetail.highlights.map((highlight, i) => (
                                    <li key={i} className="highlight-item">{highlight}</li>
                                ))}
                            </ul>
                        )}

                        {/* Render bulleted list if provided */}
                        {roleDetail.bulletPoints.length > 0 && (
                            <ul className="bullet-points">
                                {roleDetail.bulletPoints.map((point, i) => (
                                    <li key={i}>{point}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RoleBox;
