import React from 'react';
import Navbar from "../components/Navbar";
import SectionBox from "../components/SectionBox";

function Projects(props) {
    return (
        <div className='page'>
            <Navbar></Navbar>
            <SectionBox
                backgroundColor = 'lightgrey'
                textColor='black'
                borderColor='Projects'
                marginBottom = '20px'
                marginTop='20px'
                boxShadow='13px 14px 6px rgba(0, 0, 0, 0)'
                title='Work Experience'
            />
        </div>
    );
}

export default Projects;
