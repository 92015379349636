// import React, { useState, useRef, useEffect } from 'react';
// import './SkillBox.css';
//
// const SkillBox = ({
//                       icon,
//                       iconName = "",
//                       initialBgColor = 'transparent',
//                       iconColor = '#000',  // Default color for the icon
//                       borderRadius = '50px', // Default border radius to give it a rounded look
//                       scaleFactor = 0.5,
//                       imgBorderRadius = '100%',
//                       imgBorderColor = 'black',
//                       imgHoverBorderColor = 'white',
//                   }) => {
//     const [hovered, setHovered] = useState(false);
//     const skillBoxRef = useRef(null);
//     const [dynamicStyles, setDynamicStyles] = useState({
//         width: '100px',
//         height: '25px',
//         fontSize: '10px',
//         iconSize: '12px',
//     });
//
//     useEffect(() => {
//         const handleResize = () => {
//             const screenWidth = window.innerWidth;
//             const newStyles = calculateDynamicStyles(screenWidth);
//             setDynamicStyles(newStyles);
//         };
//
//         handleResize(); // Initial calculation
//         window.addEventListener('resize', handleResize);
//
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);
//
//     const calculateDynamicStyles = (screenWidth) => {
//         // Example: Adjust dimensions and font size proportionally to screen width
//         const width = `${Math.max(100, screenWidth * 0.2)}px`;
//         const height = `${Math.max(30, screenWidth * 0.05)}px`;
//         const fontSize = `${Math.max(12, screenWidth * 0.02)}px`;
//         const iconSize = `${Math.max(16, screenWidth * 0.03)}px`;
//
//         return {
//             width,
//             height,
//             fontSize,
//             iconSize,
//         };
//     };
//
//     return (
//         <div
//             className={`skill-box ${hovered ? 'hovered' : ''}`}
//             ref={skillBoxRef}
//             style={{
//                 ...dynamicStyles,
//                 borderRadius,
//                 backgroundColor: hovered ? 'black' : initialBgColor,
//                 color: hovered ? 'white' : iconColor,
//                 border: `2px solid ${hovered ? 'white' : iconColor}`,
//                 textDecoration: 'none',
//                 lineHeight: dynamicStyles.height,
//                 padding: '10px 20px',
//                 display: 'inline-flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.3s ease',
//                 boxShadow: '13px 14px 6px rgba(0, 0, 0, 0.3)',
//                 cursor: 'pointer',
//             }}
//             onMouseEnter={() => setHovered(true)}
//             onMouseLeave={() => setHovered(false)}
//         >
//             <div
//                 style={{
//                     display: 'inline-flex',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     borderRadius: imgBorderRadius,
//                     marginRight: iconName ? '8px' : '0',
//                     width: dynamicStyles.iconSize,
//                     height: dynamicStyles.iconSize,
//                     color: hovered ? 'white' : iconColor,
//                 }}
//             >
//                 {icon}
//             </div>
//             {iconName && <span>{iconName}</span>}
//         </div>
//     );
// };
//
// export default SkillBox;
import React, { useState } from 'react';
import './SkillBox.css';

const SkillBox = ({
                      icon,
                      iconName = "",
                      iconColor = '#000',  // Default color for the icon
                      baseWidth = '200px',  // Base width for the SkillBox
                      baseHeight = '80px',  // Base height for the SkillBox
                      scaleFactor = 1.2, // Scale factor to resize the SkillBox proportionally
                      borderRadius = '20px', // Rounded corners
                  }) => {
    const [hovered, setHovered] = useState(false);

    const calculatedWidth = `calc(${baseWidth} * ${scaleFactor})`;
    const calculatedHeight = `calc(${baseHeight} * ${scaleFactor})`;

    return (
        <div
            className={`skill-box ${hovered ? 'hovered' : ''}`}
            style={{
                width: calculatedWidth,
                height: calculatedHeight,
                borderRadius,
                backgroundColor: hovered ? 'black' : 'transparent',
                color: hovered ? 'white' : iconColor,
                border: `3.5px solid ${hovered ? 'white' : iconColor}`,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.3s ease',
                cursor: 'pointer',
                padding: '10px 20px',
                boxSizing: 'border-box',
                gap: '10px',  // Space between icon and text
                textAlign: 'center',
                boxShadow: '13px 14px 6px rgba(0, 0, 0, 0.3)',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div
                style={{
                    color: hovered ? 'white' : iconColor,
                    fontSize: '24px', // Size of the icon
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {icon}
            </div>
            {iconName && <span>{iconName}</span>}
        </div>
    );
};

export default SkillBox;
