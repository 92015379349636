// import React from 'react';
// import './VBox.css'; // Import your CSS file
//
// const VBox = ({ children, heightWeight = 1, maxHeight = '100%', maxWidth = '100%' }) => {
//     return (
//         <div
//             className="vbox-container"
//             style={{
//                 flexGrow: heightWeight,
//                 flexShrink: 1,
//                 flexBasis: 0,
//                 maxHeight: maxHeight,
//                 maxWidth: maxWidth,
//             }}
//         >
//             {children}
//         </div>
//     );
// };
//
// export default VBox;
// import React from 'react';
// import './VBox.css'; // Import your CSS file
//
// const VBox = ({ children, heightWeight = 1, maxHeight = '100%', maxWidth = '100%' }) => {
//     return (
//         <div
//             className="vbox-container"
//             style={{
//                 flexGrow: heightWeight,
//                 flexShrink: 1,
//                 flexBasis: 0,
//                 maxHeight: maxHeight,
//                 maxWidth: maxWidth,
//             }}
//         >
//             {children}
//         </div>
//     );
// };
//
// export default VBox;
import React from 'react';
import './VBox.css'; // Import your CSS file

const VBox = ({ children, heightWeight = 1, maxHeight = '100%', maxWidth = '100%', padding = '0' }) => {
    return (
        <div
            className="vbox-container"
            style={{
                flexGrow: heightWeight,
                flexShrink: 1,
                flexBasis: 0,
                maxHeight: maxHeight,
                maxWidth: maxWidth,
                overflowY: 'scroll', // Allow vertical scrolling
                padding: padding, // Apply padding to the VBox container
                boxSizing: 'border-box', // Ensure padding is included in the width/height calculations
            }}
        >
            {children}
        </div>
    );
};

export default VBox;
