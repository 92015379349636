import React, { useEffect, useState } from 'react';
import './BackgroundImage.css';

const BackgroundImage = ({ imageUrl, children, blur = 0, transition = true, transitionDuration = '2s' }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (transition) {
            const timeoutId = setTimeout(() => setLoaded(true), 100); // Slight delay to trigger the fade effect
            return () => clearTimeout(timeoutId);
        } else {
            setLoaded(true);
        }
    }, [transition]);

    return (
        <div className="background-wrapper" style={{ maxHeight: '100vh' }}>
            <div
                className={`background-image ${loaded ? 'fade-in' : ''}`}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    filter: `blur(${blur}px)`, // Apply the blur effect
                    transition: transition ? `opacity ${transitionDuration} ease-in-out, filter ${transitionDuration} ease-in-out` : 'none',
                }}
            />
            <div className="foreground-content">
                {children}
            </div>
        </div>
    );
};

export default BackgroundImage;
