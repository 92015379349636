import './App.css';
import Home from "./pages/Home";

function App() {
  return (
    // <div className="App">
    //   <Navbar></Navbar>
    //   <header className="App-header">
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
      <Home/>
  );
}

export default App;
