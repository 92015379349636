import React from 'react';
import './../App.css'; // Reuse the existing CSS from SectionBox

const DivSectionsBox = ({
                            children,
                            backgroundColor = "#A9A9A9",
                            borderRadius = "15px",
                            borderColor = "#ffffff",
                            maxWidth = "100%",
                            padding = "50px",
                            boxShadow = "13px 14px 6px rgba(0, 0, 0, 0.3)",
                            border = '2.5px solid',
                            textColor = 'white',
                            marginBottom = '0px',
                            marginTop = '0px'
                        }) => {
    return (
        <div
            className="custom-box"
            style={{
                backgroundColor,
                borderRadius,
                maxWidth,
                padding,
                boxShadow,
                color: textColor,
                border: `${border} ${borderColor}`,
                marginBottom,
                marginTop,
                width:'100%'
            }}
        >
            {children}
        </div>
    );
};

export default DivSectionsBox;
