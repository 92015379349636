import React from 'react';
import Navbar from "../components/Navbar";
import RoleBox from "../components/RoleBox/RoleBox";
import SectionBox from "../components/SectionBox";
import CustomSkillSection from "../components/CustomSkillSection";
import {
    SiCplusplus,
    SiFirebase,
    SiKotlin,
    SiMongodb,
    SiMysql,
    SiNeo4J,
    SiPython,
    SiRedis,
    SiSwift
} from "react-icons/si";
import {FaJava} from "react-icons/fa";
import DivSectionsBox from "../components/DivSectionBox";

function Resume(props) {
    return (
        <div className='page' style={{maxHeight: '100vh', overflow: "hidden"}}>
            <Navbar></Navbar>
            <div className='page' style={{maxHeight: '100vh', overflow: "scroll", paddingBottom:'70px'}}>
                <div className='outer-div'>
                    <SectionBox
                        backgroundColor='white'
                        textColor='black'
                        marginBottom='20px'
                        marginTop='20px'
                        boxShadow='13px 14px 6px rgba(0, 0, 0, 0)'
                        title='Work Experience'
                    />
                </div>
                <div className='outer-div'>

                    <RoleBox
                        logoUrl="https://media.licdn.com/dms/image/v2/C4E0BAQEP9rz6LMeXLg/company-logo_100_100/company-logo_100_100/0/1650461011240/glaukos_corporation_logo?e=1732752000&v=beta&t=ik2EpLyCpOT_Jj9fG6VEnKrJbku38LZ8iKobXfLGJeo"
                        companyName="Glaukos"
                        roles={{
                            role: "Software Engineering Co-Op",
                            dateLine: "May 2024 - Present",
                            location: "Burlington, MA",
                            bulletPoints: [],
                        }}
                    />
                </div>
                <div className='outer-div'>
                    <RoleBox
                        logoUrl="https://media.licdn.com/dms/image/v2/C4D0BAQFpTtdRlfpm8A/company-logo_100_100/company-logo_100_100/0/1630503896211/khoury_college_logo?e=1732752000&v=beta&t=mUeX3VqHICW2YFrrA_JUe47eDwuEx6pzkMS-u69tvsg"
                        companyName="Khoury College of Computer Sciences"
                        roles={[
                            {
                                role: "Teaching Assistant",
                                dateLine: "Jan 2024 - Apr 2024",
                                location: "Boston, MA",
                                highlights: ["Fundamentals of Computer Science 2 (CS 2510)"],
                                bulletPoints: [
                                    "Host weekly office hours for students to debug student code and clarify course content",
                                    "Grade student work and provide them with comments for feedback",
                                    "Timely and consistently answer students' questions on online forum Piazza on a rolling basis"
                                ]
                            },
                        ]}
                    />
                </div>
                <div className='outer-div'>
                    <RoleBox
                        logoUrl="https://media.licdn.com/dms/image/v2/C560BAQHdAaarsO-eyA/company-logo_100_100/company-logo_100_100/0/1630637844948/apple_logo?e=1732752000&v=beta&t=f74F7eCcHqVHruS-B7rJq3mnsaeJR3YNTa7qQLqwzIA"
                        companyName="Apple"
                        roles={{
                            role: "Sales Specialist",
                            dateLine: "Nov 2023 - Apr 2024",
                            location: "Dedham, MA",
                            bulletPoints: [
                                "Identify customer needs and provide personalized solutions",
                                "Process transactions and set up customer devices",
                                "Welcome customers as the first point of contact and guide their in-store experience"
                            ]
                        }}
                    />
                </div>
                <div className='outer-div'>
                    <RoleBox
                        logoUrl="https://media.licdn.com/dms/image/v2/C4E0BAQEskcS1QbELSg/company-logo_100_100/company-logo_100_100/0/1646755280171/framingham_public_schools_logo?e=1732752000&v=beta&t=SBr-_4Pyql3lV0gkPFJtGzJkZxuwb3w7-TayO27acIY"
                        companyName="Framingham Public Schools"
                        roles={[
                            {
                                role: "Video Production Instructor",
                                dateLine: "Jun 2021 - Aug 2023",
                                location: "Framingham, MA",
                                highlights: ["(Seasonal Position Held Jun 2021-Aug 2021, Jun 2022-Aug 2022 & Jun 2022-Aug 2023)",],
                                bulletPoints: [
                                    "Taught a class on Pre-Production / Production",
                                    "Guided students on how to use equipment in a news control room",
                                    "Demonstrated use of cameras and camera shots & angles",
                                    "Made professional videos for the middle schools in Framingham for “Intro to Middle School\"",]
                            },
                            {
                                role: "Robotics Mentor",
                                dateLine: "Sep 2021 - Jun 2022",
                                bulletPoints: [
                                    "Founding member of Robotics Club at Fuller Middle School",
                                    "Guiding students in building basic robots and programming"
                                ]
                            }
                        ]}
                    />
                </div>
                <div className='outer-div'>
                    <SectionBox
                        backgroundColor='white'
                        textColor='black'
                        marginBottom='20px'
                        marginTop='20px'
                        boxShadow='13px 14px 6px rgba(0, 0, 0, 0)'
                        title='Education'
                    />

                </div>

                <div className='outer-div'>
                    <RoleBox
                        logoUrl="https://media.licdn.com/dms/image/v2/C4D0BAQGwTPCvlTQlvw/company-logo_100_100/company-logo_100_100/0/1630531267482/northeastern_university_logo?e=1732752000&v=beta&t=YS2A6gqB5hlOxb3Wk0cpIKeR64zFIpJT-TKsCc0zco0"
                        companyName="Northeastern University"
                        roles={[
                            {
                                role: "BS. Computer Science and Business Administration",
                                dateLine: "2022 - 2026",
                                location: "Boston, MA",
                                highlights: [],
                                bulletPoints: []
                            }
                        ]}
                    />
                </div>

            </div>
        </div>
    );
}

export default Resume;
