import React from 'react';
import CustomSkillSection from "../components/CustomSkillSection";
import {
    SiAndroidstudio,
    SiCplusplus,
    SiFirebase, SiFlask, SiGit, SiIntellijidea, SiJunit5,
    SiKotlin,
    SiMongodb,
    SiMysql,
    SiNeo4J, SiPandas, SiPowerbi,
    SiPython,
    SiRedis, SiScikitlearn, SiSelenium, SiSqlite,
    SiSwift, SiXcode
} from "react-icons/si";
import {FaJava} from "react-icons/fa";
import DivSectionsBox from "../components/DivSectionBox";
import Navbar from "../components/Navbar";
import SectionBox from "../components/SectionBox";
import DivSectionBox from "../components/DivSectionBox";
import VBox from "../components/Vbox/VBox";
import {DiIntellij, DiRedis} from "react-icons/di";
import {AiFillAndroid, AiFillApple} from "react-icons/ai";
import {IoLogoApple} from "react-icons/io";

function Skills(props) {
    return (
        <div className='page'>
            <Navbar></Navbar>
            <VBox>
                <div style={{width:'90%', scrollPadding:"initial"}}>
                    {/*<div>*/}
                    {/*    <h1>Skills</h1>*/}
                    {/*</div>*/}
                    <SectionBox
                        backgroundColor = 'black'
                        textColor='white'
                        marginBottom = '20px'
                        marginTop='20px'
                        boxShadow='13px 14px 6px rgba(0, 0, 0, 0)'
                        title='Skills'
                        maxWidth='100%'
                        width='100%'
                    />
                    <CustomSkillSection
                        title="Languages"
                        backgroundColor="black"
                        skills={[
                            {icon: <SiPython/>, iconName: "Python", iconColor: "#4584b6"},
                            {icon: <FaJava/>, iconName: "Java", iconColor: "#f89820"},
                            {icon: <SiKotlin/>, iconName: "Kotlin", iconColor: "#B125EA"},
                            {icon: <SiSwift/>, iconName: "Swift", iconColor: "#f05138"},
                            {icon: <SiCplusplus/>, iconName: "C++", iconColor: "#00549D"},
                        ]}
                    />
                    <CustomSkillSection
                        title="Databases"
                        backgroundColor="black"
                        skills={[
                            {icon: <SiMysql/>, iconName: "MySQL", iconColor: "#00758f"},
                            {icon: <SiMongodb/>, iconName: "MongoDB", iconColor: "#4DB33D"},
                            {icon: <DiRedis/>, iconName: "Redis", iconColor: "#D82C20"},
                            {icon: <SiFirebase/>, iconName: "Google Firebase", iconColor: "#FFA611"},
                            {icon: <SiNeo4J/>, iconName: "Neo4j", iconColor: "#0164a1"},

                        ]}
                    />
                    <CustomSkillSection
                        title="Frameworks, Libraries, General"
                        backgroundColor="black"
                        skills={[
                            {icon: <AiFillApple/>, iconName: "iOS", iconColor: "#FFFFFF"},
                            {icon: <AiFillAndroid/>, iconName: "Android", iconColor: "#A4C639"},
                            {icon: <SiFlask/>, iconName: "Flask", iconColor: "#1b47c4"},
                            {icon: <SiPandas/>, iconName: "Pandas", iconColor: "#4B8BBE"},
                            {icon: <SiScikitlearn/>, iconName: "Sci Kit Learn", iconColor: "#e59326"},
                            {icon: <SiGit/>, iconName: "Git", iconColor: "grey"},
                            {icon: <SiAndroidstudio/>, iconName: "Android Studio", iconColor: "#FFFFFF"},
                            {icon: <SiIntellijidea/>, iconName: "IntelliJ", iconColor: "#FF8C00"},
                            {icon: <SiXcode/>, iconName: "Xcode", iconColor: "#1875df"},
                            {icon: <SiJunit5/>, iconName: "Junit5", iconColor: "#dc514a"},
                            {icon: <SiPowerbi/>, iconName: "PowerBi", iconColor: "#fdc006"},
                            {icon: <SiSelenium/>, iconName: "Selenium", iconColor: "#42b029"},

                        ]}
                    />

                </div>
            </VBox>
        </div>
           );
}

export default Skills;
